<template>
    <div style="padding: 20px">
        <div class="flex justify-between">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="新客名称">
                    <el-input v-model="searchForm.search" placeholder="新客名称/手机号/关键字"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
                    <el-button type="primary"  @click="exportMessage()">导出新客咨询</el-button>
                </el-form-item>
            </el-form>
            <!-- 操作按钮 -->
            <el-form :inline="true">
                <el-form-item>
                    <el-button icon="el-icon-plus" @click="toEdit()">添加新客咨询</el-button>
                    <!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="name" label="新客名称"></el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column prop="consulting_project" label="咨询项目"></el-table-column>
            <el-table-column prop="desc" label="咨询具体事项"></el-table-column>
            <el-table-column prop="created_at" label="咨询时间"></el-table-column>
            <el-table-column prop="agent" label="经办人"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button @click="toEdit(scope.row)" size="mini">编辑</el-button>
                    <el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
                        <el-button slot="reference" size="mini">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div class="text-center margin-top">
            <el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="pageSizes" :page-size="searchForm.page_size"
                           layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loading: false, // 表格加载状态
                tableData: [], // 数据
                pageSizes: [],
                searchForm: {
                    search: '', // 否 string 用于搜索的关键字
                    page: 1, // 否 int 页码
                    page_size: 10, // 否 int 页脚
                },
                total: 0,
            };
        },
        mounted() {
            // 查询数据
            this.search()
        },
        methods: {
            search() {
                this.loading = true;
                this.$api.table.newConsultList(this.searchForm).then(res => {
                    console.log(res)
                    this.tableData = res.data.data;
                    this.total = res.data.total;
                    this.pageSizes = res.data.per_page.toString().split()
                    this.searchForm.page_size = res.data.per_page
                    this.loading = false;
                })
            },
            pageSizeChange(size) {
                this.searchForm.page_size = size;
                this.search()
            },
            pageChange(page) {
                this.searchForm.page = page;
                this.search()
            },
            toEdit(item) {
                if (item) {
                    this.$router.push(`/tables/newGuest/edit/${item.id}`)
                } else {
                    this.$router.push(`/tables/newGuest/edit`)
                }
            },
            del(item) {
                const loading = this.$loading()
                this.$api.table.delNewConsult({
                    id: item.id
                }).then(res => {
                    loading.close()
                    if (res.code == 200) {
                        this.$message.success(`操作成功`);
                        this.search()
                    } else {
                        this.$message.error(res.msg);
                    }
                }).catch(err => {
                    loading.close()
                    this.$alert(err);
                });
            },
            exportMessage(){
                this.$api.table.consultExport({
                    search:this.searchForm.search,
                }).then(res => {
                    console.log(res)
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: "application/vnd.ms-excel"
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl)
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '新客咨询.xls');
                    a.click();
                })
            }
        },
    };
</script>

<style scoped lang="scss">
    .cover {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 10px;
    }
</style>
